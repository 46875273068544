/*
 * Helpers
 */

/*
 * Clearfix: contain floats
 * http://cssmojo.com/latest_new_clearfix_so_far/
 */
%cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */
%hidden {
  display: none !important; // sass-lint:disable-line no-important
  visibility: hidden;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
%visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

/**
 * Typo
 */
%fw-lightest {
  font-weight: 100;
}

%fw-light {
  font-weight: 300;
}

%fw-normal {
  font-weight: 400;
}

%fw-medium {
  font-weight: 500;
}

%fw-semi {
  font-weight: 600;
}

%fw-bold {
  font-weight: 700;
}

%fw-ultra {
  font-weight: 800;
}

%fw-black {
  font-weight: 900;
}

%fs-italic {
  font-style: italic;
}

%fs-normal {
  font-style: normal;
}

%text-left {
  text-align: left;
}

%text-center {
  text-align: center;
}

%text-right {
  text-align: right;
}

%text-justify {
  text-align: justify;
}

%text-uppercase {
  text-transform: uppercase;
}

%text-notransform {
  text-transform: none;
}

%text-nodecoration {
  text-decoration: none;
}

%button-nostyle {
  display: inline-block;
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  appearance: none;
  cursor: pointer;
}

%link--pink {
  @extend %fw-bold;

  color: $c-pink-medium;
  font-family: $ff-alt;
  font-size: 1.5rem;
  line-height: 1.5;
  text-decoration: none;
  background-image: linear-gradient($c-pink-medium, $c-pink-medium),
    linear-gradient($c-blue-dark, $c-blue-dark);
  background-size: 100% 2px, 0 2px;
  background-repeat: no-repeat;
  transition: color 0.3s $ease-out-quad, background-size 0.5s $ease-out-quad,
    background-position 0.5s $ease-out-quad;

  &:hover,
  &:focus {
    color: $c-blue-dark;
    background-position: 100% 100%, 0 100%;
    background-size: 0 2px, 100% 2px;
  }

  @include mq(m) {
    font-size: 1.6rem;
  }

  @include mq(xl) {
    font-size: 1.8rem;
  }
}

%list-nostyle {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%select-nostyle {
  display: inline-block;
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  -moz-appearance: none; // sass-lint:disable-line no-vendor-prefixes

  &::-ms-expand {
    // sass-lint:disable-line no-vendor-prefixes
    display: none;
  }
}

/*
 * Colors
 */
%color-white {
  color: $white;
}

%color-black {
  color: $black;
}

%color-dark {
  color: $c-blue-dark;
}

%color-light {
  color: $c-light;
}

// Box Shadow
%box-shadow {
  box-shadow: 0 13px 40px rgba($black, 0.1);
}

%card-shell {
  @extend %box-shadow;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  .is-dark & {
    box-shadow: 0 20px 40px 0 rgba($white, 0.2);
  }
}

%btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -0.3rem;
  padding: $spacing * 0.5 $spacing * 0.75;
  color: $white;
  font-family: $ff-alt;
  text-decoration: none;
  background-color: $c-green-light;
  border: 0;
  border-radius: 50px;
  transition: background-color 0.3s $ease;

  &.disabled,
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-hidden {
    display: none;
  }

  &[class*='--big'] {
    // height: 4.6rem;
    padding: $spacing $spacing * 1.1;
  }

  &:hover,
  [class*='hero__slides__item']:hover &,
  [class*='product-tv__slider__item']:hover & {
    background-color: darken($c-green-light, 5);

    &[class*='--outline'] {
      background-color: $c-anthracite-light;
    }
  }

  &[class*='--outline'] {
    background-color: $white;
    border: 0.2rem solid $c-anthracite-light;
  }

  &[class*='--icon-left'] {
    flex-direction: row-reverse;
  }

  @include mq(m) {
    // height: 4.2rem;
    padding: $spacing * 0.75 $spacing;

    &[class*='--big'] {
      height: 5.4rem;
      padding: 0 $spacing * 1.5;
    }
  }

  @include mq(xl) {
    // height: 4.6rem;

    &[class*='--big'] {
      height: 6rem;
      padding: 0 $spacing * 1.5;
    }
  }
}

%btn-tag {
  padding: 0.65rem 1.3rem;
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  background-color: $c-mandarine;
  border-radius: 5rem;
}

// TO DO REFACTO
%slider-legacy {
  ::v-deep {
    .slidy-controls {
      position: absolute;
      z-index: 200;
      top: calc(((28 / 36) * 100vw) / 2.2);
      left: 0;
      display: flex;
      flex-direction: column;

      @include mq($until: 'l') {
        right: 0;
        width: 60% !important;
        margin: 0 auto;
      }

      @include mq($until: 's') {
        width: 100% !important;
      }

      @include mq('l') {
        top: auto;
        bottom: $spacing * 3;
        left: $spacing * 3.5;
      }

      @include mq('xl') {
        left: $spacing * 6;
      }
    }

    .slidy-controls__item,
    [class*='slidy-controls__item--'] {
      @extend %button-nostyle;

      height: 5rem;

      svg {
        width: 4rem;
        height: 4rem;
        padding: $spacing / 1.5;
        border: 2px solid $c-light;
        border-radius: 100%;
        fill: $c-light;
        transition: 0.2s $ease-out-quad;
        transition-property: background-color, fill;

        @include mq(l) {
          width: 5rem;
          height: 5rem;
        }
      }

      &.is-disabled {
        opacity: 0.2;
      }

      & + & {
        margin-top: 1rem;
      }

      &:hover:not(.is-disabled) {
        svg {
          background-color: $white !important;
          fill: $c-anthracite-light;
        }
      }

      @include mq($until: 'l') {
        position: absolute;
      }

      // disable hover on touchdevice
      @media (hover: none) {
        &:hover:not(.is-disabled) {
          svg {
            background-color: transparent !important;
            fill: $c-light;
          }
        }
      }
    }

    [class*='slidy-controls__item--'][class*='--prev'] {
      left: $spacing;

      svg {
        transform: rotate(90deg);
      }
    }

    [class*='slidy-controls__item--'][class*='--next'] {
      right: $spacing;

      svg {
        transform: rotate(270deg);
      }

      @include mq(xl) {
        order: -1;
      }
    }

    .slidy-nav__item.is-active {
      button {
        color: $c-light;
        background-color: $c-blue-dark;
        border: 0;
        box-shadow: 0 0 40px 0 rgba($c-blue-contrast, 0.3);
      }
    }

    .slidy-nav__item.is-seen {
      button {
        @include mq('l') {
          transform: translateX(-30%);
        }
      }
    }
  }
}

%voo-tv-plus-legacy {
  ::v-deep {
    .voo-tv-plus__slider {
      position: relative;
      width: calc(100% + 2rem);
      margin-top: 5rem;

      &::before {
        content: '';
        position: absolute;
        top: -1rem;
        right: 0;
        bottom: -1rem;
        left: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: lighten($gray-lightest, 4);
      }

      .generic-slider {
        margin-left: $spacing * 1.5;
      }

      .generic-slider__slides__item {
        transition: none;

        img {
          border-radius: 5px;
        }
      }

      .flickity-viewport {
        overflow: visible;
      }

      .generic-slider__slides__item {
        width: 33%;
        padding-right: 2rem;
        transition: opacity 0.3s;

        &[class*='is-viewed'] {
          opacity: 0;
          pointer-events: none;
        }
      }

      @include mq(m) {
        width: calc(100% + 7rem);
        margin-top: 20rem;

        &::before {
          top: -5rem;
          bottom: -4rem;
        }
      }

      @include mq(l) {
        width: 50vw;
      }

      @include mq(xl) {
        display: flex;

        .generic-slider {
          margin-left: 0;
        }
      }
    }

    .voo-tv-plus__slider__slogan {
      @extend %fw-medium;

      position: relative;
      z-index: 1;
      padding: 0 $spacing * 1.5 $spacing;
      font-size: 1.5rem;
      white-space: nowrap;

      svg {
        fill: $c-pink-medium;
      }

      span {
        display: block;
      }

      @include mq(m) {
        &::before {
          content: '';
          position: absolute;
          top: -13.8rem;
          left: 0;
          display: block;
          width: 11.8rem;
          height: 8.8rem;
          background: url('/assets/images/tvplus/avatar-1-rail.png') no-repeat;
          background-size: cover;
        }
      }

      @include mq(l) {
        font-size: 2.4rem;
      }

      @include mq(xxxl) {
        padding: $spacing $spacing * 5 $spacing $spacing * 1.5;
      }
    }
  }
}

%table-legacy {
  ::v-deep {
    $width: 80%;
    $max-width: 100rem;

    .table {
      position: relative;
      padding-top: $spacing * 2;
      padding-bottom: $spacing * 2;

      @include mq(m) {
        padding-top: $spacing * 4;
        padding-bottom: $spacing * 4;
      }

      @include mq(xl) {
        padding-top: $spacing * 6;
        padding-bottom: $spacing * 6;
      }
    }

    .table-desktop,
    [class*='table-desktop--'] {
      @include mq(l) {
        &[class*='--solo'] {
          width: $width;
          max-width: $max-width;
          margin: 0 auto;
        }
      }
    }

    .table__layout,
    [class*='table__layout--'] {
      @include center-x;

      z-index: -1;
      top: 0;
      grid-gap: 1rem;
      width: 100%;
      height: 100%;
      padding-top: $spacing * 2;
      padding-bottom: $spacing * 2;

      @include mq(m) {
        padding-top: $spacing * 4;
        padding-bottom: $spacing * 4;
      }

      @include mq(l) {
        &[class*='--solo'] {
          width: $width;
          max-width: $max-width;
          margin: 0 auto;
        }
      }

      @include mq(xl) {
        display: grid;
        grid-template-rows: 27rem 1fr 5rem;
        grid-template-columns: 31% 34% 33.3%;
        padding-top: $spacing * 6;
        padding-bottom: $spacing * 6;

        .net-comparison-table & {
          padding-bottom: $spacing * 5;
        }

        &[class*='--solo'] {
          grid-template-columns: 1fr 35rem;
        }

        .is-solo & {
          grid-template-columns: 30% 22.5% 22.5% 22.5%;
        }

        .pack & {
          grid-template-rows: 30rem 1fr 12rem;
        }

        .rates & {
          grid-template-rows: 18rem 1fr 12rem;
        }
      }

      > div {
        box-shadow: 0 0 20px rgba($black, 0.2);
        border-radius: 1rem;
      }
    }

    .table__layout__col,
    [class*='table__layout__col--'] {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-column: 4;
      background: $white;

      &[class*='table__layout__col--'][class*='--solo'] {
        grid-column: 2;
      }
    }

    .table__layout__col:nth-child(2) {
      grid-column: 3;
      background: $white;
    }

    .table__layout__col:nth-child(3) {
      grid-column: 2;
      background: $white;
    }

    .table__layout__table {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 4;
      margin-right: 1rem;
      background: transparent;

      [class*='table__layout--'][class*='--solo'] & {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    .table-content,
    [class*='table-content--'] {
      [class*='table-desktop--solo'] & {
        width: 100%;
      }
    }

    .table-desktop,
    [class*='table-desktop--'] {
      .table-content__header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
      }

      &[class*='--solo'] {
        .table-content__header__block {
          position: relative;
          @include mq($until: xl) {
            width: 100%;
          }

          @include mq(xl) {
            width: 35rem;
          }
        }
      }

      &.is-solo {
        .table-content__order__button-outer
          + .table-content__order__button-outer {
          margin-left: 1rem;
        }
      }
    }

    .table-content__header {
      @include mq($until: xl) {
        position: sticky;
        z-index: layer(high);
        top: 1rem;
        width: 100%;
        background-color: $white;
      }
    }

    .table-content__header__sale {
      position: absolute;
      z-index: 15;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      transform: translate(0, -50%);

      @include mq($until: 'xl') {
        display: none;
      }
    }

    .table-content__header__main {
      position: relative;
      z-index: 10;
      flex: 1 auto;
      padding: $spacing * 2 $spacing $spacing * 0.5;
      text-align: center;

      @include mq(m) {
        padding: $spacing * 1.75 $spacing * 1.5 $spacing * 0.5;
      }
    }

    .table-content__header__block {
      position: relative;
      width: 49%;
      margin-right: 1.5rem;
      border-radius: 1rem 1rem 0 0;

      @include mq(xl) {
        width: 34%;
        height: 27rem;
        margin-right: 0;

        .pack & {
          height: 30rem;
        }

        .rates & {
          height: 18rem;
        }
      }

      .selection__products,
      .product-card__pricing {
        @include mq($until: m) {
          display: none;
        }
      }

      .is-solo & {
        width: 32%;

        @include mq(xl) {
          width: 23%;
          margin-right: 0;
        }
      }

      .product-card__message {
        @include mq($until: 'xl') {
          padding: 0;
        }
      }
    }

    .table-content__data__item-inner {
      width: 100%;
    }

    .table-content__data-outer {
      overflow: hidden;
    }

    .table-content__order {
      display: flex;
      justify-content: flex-end;
      min-height: 7rem;
    }

    .table-content__order__button-outer {
      display: flex;
      justify-content: center;
      width: 50%;
      padding: 4rem 0;

      @include mq(xl) {
        width: 34.5%;
      }

      .is-solo & {
        width: 32%;

        &:first-child {
          display: flex;
        }

        @include mq(xl) {
          width: 22.5%;
        }
      }

      [class*='table-desktop--solo'] & {
        width: 100%;

        &:first-child {
          display: flex;
        }

        @include mq(xl) {
          width: 35rem;
        }
      }
    }

    .table-content__data {
      width: 100%;
      font-family: $ff-alt;
      border-top: 1px solid $c-light-gray;
      border-collapse: collapse;
      border-spacing: 0;

      @include mq(xl) {
        border: 0;
      }

      [class*='table-desktop--solo'] & {
        width: 100%;
      }
    }

    .table-content__data__rgu {
      @extend %text-uppercase;
      @extend %fw-black;

      display: block;
      width: 100%;
      padding-top: $spacing;
      padding-bottom: $spacing;
      color: $c-pink-medium;
      font-size: 1.8rem;

      @include mq(xl) {
        padding-right: calc(68% + 3rem);
        text-align: right;

        .is-solo & {
          padding-right: 71%;
        }

        [class*='table-desktop--solo'] & {
          padding-right: 38rem;
        }
      }
    }

    .table-content__data__spec-outer {
      display: flex;
      align-items: center;
      @include mq($until: xl) {
        flex-wrap: wrap;
      }

      @include mq(xl) {
        &:nth-child(odd) {
          width: 100%;
          background: rgba(200, 200, 200, 0.1);
        }
      }
    }

    .table-content__data__spec,
    .table-content__data__value {
      width: 50%;
    }

    .table-content__data__spec {
      @extend %fw-normal;

      display: block;
      width: 100%;
      padding-top: $spacing / 2;
      padding-right: 3rem;
      padding-bottom: $spacing / 2;
      line-height: 1.6;
      text-align: left;

      @include mq(xl) {
        width: 32%;
        padding-left: $spacing;
        text-align: right;

        .is-solo & {
          width: 31%;
        }

        [class*='table-desktop--solo'] & {
          width: calc(100% - 35rem);
        }

        span {
          text-transform: uppercase;
        }
      }

      small {
        display: block;
        opacity: 0.5;
      }
    }

    .table-content__data__value {
      @extend %text-center;
      @extend %fw-bold;

      display: block;
      width: 34%;
      padding: $spacing / 2;
      text-align: center;

      [class*='table-desktop--'][class*='--solo'] & {
        width: 32%;
      }

      @include mq($until: xl) {
        width: 49%;

        [class*='table-desktop--'][class*='--solo'] & {
          width: 100%;
        }
      }

      .is-solo & {
        width: 32%;

        @include mq(xl) {
          width: 23%;
        }
      }

      @include mq(xl) {
        [class*='table-desktop--'][class*='--solo'] & {
          width: 35rem;
        }
      }

      & + & {
        display: block;
        margin-left: 1rem;
      }

      @include mq($until: xl) {
        background: rgba(200, 200, 200, 0.1);

        & + & {
          display: block;
          margin-left: 2%;
        }
      }

      small {
        display: block;
        opacity: 0.5;
      }
    }

    legend,
    th {
      line-height: 4rem;
    }

    td {
      padding: 0;
    }

    table table {
      border-collapse: collapse;
    }
  }
}

%switcher {
  ::v-deep {
    position: relative;
    display: inline-flex;
    background: $white;
    border: 0.1rem solid $c-blue-light-pale;
    border-radius: 5px;

    &.is-disabled {
      pointer-events: none;
      opacity: 0.8;
    }

    &.is-hidden {
      display: none;
    }

    .switcher__slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 10rem;
      height: 100%;
      background: $c-anthracite-light;
      border-radius: 5px;
      opacity: 0;
      transform: scale(1.05, 1.2);
    }

    .switcher__option {
      position: relative;
      z-index: 10;
      padding: $spacing * 0.5 $spacing;
      color: $c-blue-dark;
      line-height: 1;
      background: transparent;
      border: 0;

      // &:not(:last-child) {
      //   border-right: .1rem solid $c-blue-light-pale;
      // }

      &.is-active {
        color: $white;
        pointer-events: none;
      }

      @include mq(xxxl) {
        padding: $spacing * 0.5 $spacing * 1.5;
      }
    }

    .switcher__option__logo {
      display: block;
      // width: auto;
      max-width: 5rem;
      height: 1.8rem;
      fill: $c-anthracite-light;
      transition: fill 0.3s $ease;

      .switcher__option.is-active & {
        fill: $white;
      }

      @include mq(m) {
        max-width: 8rem;
        height: 2.4rem;
      }
    }

    .switcher__option__label {
      @extend %fw-bold;

      color: $c-anthracite-light;
      font-family: $ff-alt;
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: color 0.3s $ease;

      .switcher__option.is-active & {
        color: $white !important;
      }

      @include mq(m) {
        font-size: 1.2rem;
      }

      @include mq(l) {
        font-size: 1.6rem;
      }

      @include mq(xxl) {
        font-size: 1.6rem;
      }

      .no-price-difference & {
        strong {
          font-size: 2.4rem !important;
        }

        small {
          display: none !important;
          // opacity: 0 !important;
        }
      }
    }
  }
}
