/* stylelint-disable scss/no-global-function-names, alpha-value-notation, color-function-notation */
/**
 * Dev, based on $env (from gulp)
 */
$env: 'dev' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$black: rgb(0, 0, 0) !default;
$gray-darkest: rgb(32, 32, 32) !default;
$gray-darker: rgb(64, 64, 64) !default;
$gray-dark: rgb(96, 96, 96) !default;
$gray-medium: rgb(111, 113, 117) !default; // Used
$gray-light: rgb(160, 160, 160) !default;
$gray-lighter: rgb(192, 192, 192) !default;
$gray-lightest: rgb(239, 239, 239) !default;
$white: rgb(255, 255, 255) !default;
$transparent: transparent !default;

// Dev colors
$c-dev-bg: #3e4b55 !default;
$c-dev-error: #d90b0b !default;
$c-dev-warning: #f50 !default;
$c-dev-info: #829deb !default;

// Semantic/project colors
$c-mandarine: #fea347 !default;
$c-light: $white !default;
$c-cyan-contrast: #0cbaba !default;
$c-blue-contrast: #88b6ff !default;
$c-blue-lightest: #a5b9c4 !default;
$c-blue-lighter: #808b9b !default;
$c-blue-light: #3c4d68 !default;
$c-blue-medium: #222c3e !default;
$c-blue-dark: #121a28 !default; // Midblue
$c-blue-darker: #111019 !default;
$c-blue-darkest: #01091c !default;
$c-blue-light-pale: #d1dce2 !default;
$c-blue-pale: #7c96a4 !default;
$c-blue-indigo: #22235c !default;
$c-blue-metal: #274b80 !default;
$c-blue-mat: #3e68aa !default;
$c-midnight-darker: #081828 !default;
$c-midnight-darkest: #030e1b !default;
$c-gray-darkest: #1b1b1b !default;
$c-gray-lightest: #e0e0e0 !default;
$c-green-light: #7db200 !default;
$c-gray-light: #b5b5b5 !default;
$c-light-gray: #efefef !default;
$c-gray: rgb(151, 151, 151) !default;
$c-gray-dark: hsl(220deg, 3%, 45%) !default;
$c-gray-darker: #4d4d4d !default;
$c-pink-light: #ff80b5 !default;
$c-pink-lightest: #f6c0da !default;
$c-pink-medium: #d4007a !default;
$c-pink-dark: #7a2a58 !default;
$c-purple: #8a1e65 !default;
$c-anthracite-light: #274856 !default;
$c-anthracite-lighter: #2d374e !default;
$c-orange: #ffa300 !default;
$c-white-dark: #fafafa !default;
$c-error: #d64618 !default;
$c-burgundy: #832652 !default;
$c-light-blue: #007bb6 !default;
$c-zuny-lighter: #0ff !default;
$c-zuny-light: #00ffd7 !default;
$c-bunting: #25334a !default;
$c-nevada: #6f7175 !default;
$c-promo: #141629 !default;
$c-promo-card: #081728 !default;
$c-promo-card-headline: #274856 !default;
$c-promo-shadow: #312d72 !default;
$c-weldon-blue: #7c96a4 !default;
$c-cultured: #f7f7f7 !default;

// Project colors
$c-proximus: #542791 !default;
$c-orange-company: #f60 !default;
$c-violet: #8e00ff !default;
$c-proximus-variant: #8665b1 !default;

// Primary
$c-gallery: #ebebeb !default;

// Secondary
$c-fiord: #3c4d68 !default;
$c-regent-gray: #808b9b !default;
$c-tickle-me-pink: #ff80b5 !default;

// Background colors
$bg-orange: 'is-orange' !default;
$bg-white: 'is-white' !default;
$bg-light-gray: 'is-light-gray' !default;
$bg-lighter-gray: 'is-lighter-gray' !default;
$bg-lightest-gray: 'is-lightest-gray' !default;
$bg-light-blue: 'is-light-blue' !default;
$bg-dark-blue: 'is-dark-blue' !default;
$bg-darkest-blue: 'is-midnight-darkest' !default;
$bg-midnight-darker: 'is-midnight-darker' !default;
$bg-darker-blue: 'is-darker-blue' !default;
$bg-light-blue: 'is-light-blue' !default;
$bg-colors: (
  $bg-orange: $c-orange,
  $bg-white: $white,
  $bg-light-gray: rgb(239, 239, 239),
  $bg-lighter-gray: $c-cultured,
  $bg-lightest-gray: $c-white-dark,
  $bg-light-blue: $c-blue-light,
  $bg-dark-blue: #263043,
  $bg-darker-blue: $c-blue-dark,
  $bg-darkest-blue: $c-midnight-darkest,
  $bg-midnight-darker: $c-midnight-darker,
) !default;
$bg-starry-night-eoy-promo-2022: #091829;

// Blog Tip colors
$tips-schemes: (
  'do': #f5f9e8,
  'dont': #fcecef,
  'warning': #fff4f0,
  'info': #edf3ff,
  'tip': #fef1f8,
  'didyouknow-1': #f0f9f6,
  'didyouknow-2': #f5edfc,
  'didyouknow-3': #f3f7ff,
) !default;
$tips-schemes-hue: (
  'do': #7db200,
  'dont': rgba(212, 0, 49, 0.7),
  'warning': #ff7774,
  'info': rgba(0, 88, 255, 0.6),
  'tip': #d4007a,
  'didyouknow-1': darken(#f0f9f6, 30%),
  'didyouknow-2': darken(#f5edfc, 30%),
  'didyouknow-3': darken(#f3f7ff, 30%),
) !default;

// Video player
$plyr-color-main: $c-pink-medium !default;

// Promo Hardware Color
$c-gift-color-a: #df84b5 !default;
$c-gift-color-b: #87094d !default;
$c-gift-color-c: #b40c67 !default;

// Z Layers
$z-layers: (
  lowest: 0,
  lower: 10,
  low: 20,
  high: 30,
  higher: 40,
  highest: 50,
  infinite: 999,
) !default;
$z-index-header: 998 !default;

/**
 * Typography
 */
$base-font-size: 10px !default;
$ff-default: roboto, sans-serif !default;
$ff-alt: 'museo-sans-rounded', sans-serif !default;
$ff-zuny: zuny, sans-serif !default;
$body-text-s: 1.6em !default;
$body-text-m: 1.6em !default;
$body-text-l: 1.8em !default;
$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
) !default;

// The `scalable-text-map` contains options for our `scalable-text` mixin.
// For each entry, we define a `min` map and a `max` map.
// In this example this means:
// - For a screen < 400px, the `h1` will be 24px.
// - For a screen > 800px, the `h1` will be 36px.
// - For any screen size in between, the `h1` will scale between 24px and 36px.
$scalable-text-map: (
  h1: (
    min: (
      24px,
      'small',
    ),
    max: (
      36px,
      'large',
    ),
  ),
  p: (
    min: (
      13px,
      'small',
    ),
    max: (
      18px,
      'large',
    ),
  ),
) !default;

/*
 * Layout & spacing
 */
$dynamic-100vh: calc(#{var(--vh, 1vh)} * 100) !default;
$spacing: 2rem !default;
$wrapper-width: get-breakpoint('xxl') !default;
$columns-number: 12 !default;
$header-height: 5rem !default;
$border-radius: 8px !default;
$spacings-map: (
  xxl: (
    l: 160px,
    m: 120px,
    s: 80px,
  ),
  xl: (
    l: 120px,
    m: 80px,
    s: 60px,
  ),
  l: (
    l: 80px,
    m: 60px,
    s: 40px,
  ),
  m: (
    l: 60px,
    m: 40px,
    s: 30px,
  ),
  s: (
    l: 40px,
    m: 30px,
    s: 20px,
  ),
  xs: (
    l: 20px,
    m: 15px,
    s: 10px,
  ),
  xxs: (
    l: 10px,
    m: 10px,
    s: 10px,
  ),
) !default;

// $content-width: 144rem !default;

// Wrapper
$wrapper-spacing-s: $spacing !default;
$wrapper-spacing-m: $spacing * 3.5 !default;
$wrapper-spacing-xl: $spacing * 6 !default;
$wrapper-width-s: get-breakpoint('s') !default;
$wrapper-width-m: get-breakpoint('m') !default;
$wrapper-width-l: get-breakpoint(
  'l'
) !default; // getting wrong values from those!
$wrapper-width-xl: $wrapper-width !default;

/**
 * RWD
 */
$mq-responsive: true !default;
$mq-static-breakpoint: 'large' !default;

/**
 * Misc
 */
// Used on robbie glass-cards for exemple.
// Use higher lvl-x for higher elevation.
$box-shadow-lvl-0: 0 0 15px rgba($black, 0.1) !default;
$box-shadow-lvl-1: 0 0 15px rgba(3, 14, 27, 0.2) !default;
$box-shadow-lvl-2: 0 0 35px rgba($black, 0.1) !default;
$parallax-intro-height: 300vh !default;
$channels-content-height: 100vh !default;
$channels-thumbnails-offet: -25vh !default;
$universes-content-height: 100vh !default;
